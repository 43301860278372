import React, { useState, useEffect } from 'react';
import './styles/SearchHymn.css'; // Import your CSS file for styling
import {useLocation, useNavigate} from 'react-router-dom';
import {MdArrowBack} from 'react-icons/md';

const GospelNotes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [authorImage, setAuthorImage] = useState('');
    const flavor = location?.state?.flavor;
    const title = location?.state?.title;

    const recipient = 'support@gospelhymns.cartglu.com';
    const subject = 'Contact Us | Gospel Hymn Book';
    const body = 'Kindly share your request/questions.';

    const openGmailCompose = () => {
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
      };

    const goBack = () => {
      navigate(-1)
    }

  return (
    <div className="absolute z-10 bg-white overflow-hidden">
    <div className="search-page" style={{height: '100%'}}>
      <div className="toolbar flex items-center">
        <div className="go-back">
            <MdArrowBack size="26" className="text-white" onClick={goBack}/>
        </div>
        <h1 className="toolbar-title pl-1 text-md"><span className="uppercase">The Good News</span></h1>
      </div>

        {
        !location?.state?.mode && (
          <>
           <div className="flex flex-col items-center mt-6">
             <h1 className="text-2xl mt-3 font-bold">{title}</h1>
              <div style={{ height: '55vh' }} className="pl-7 pr-7 pt-3 mt-5 overflow-y-scroll no-scrollbar">
              <p style={{ whiteSpace: 'pre-line' }}>{location?.state?.message}</p>

              </div>
          </div>
          <div>
                <p className="text-center text-gray-500 mt-5">Have a question?</p>
                <div className="w-full flex justify-center items-center mt-4">
                    <button onClick={openGmailCompose} className="h-13 p-3 font-bold text-white bg-red-400 focus:outline-none" >SUBMIT A REQUEST</button>
                </div>
            </div>
          </>
           
        )
      }

    {
        location?.state?.mode === "desktop" && (
            <div className="flex flex-col mt-5 overflow-hidden">
                <div className="mx-7 flex items-center justify-center w-11/12">
                  <h1 className="text-2xl mt-3 font-bold">{title}</h1>
                </div>
                <div style={{ height: '63vh', paddingRight: '10px' }} className="p-7 mt-4 overflow-y-scroll no-scrollbar md:mr-4">
                    <p className="w-full text-lg sm:text-sm" style={{ whiteSpace: 'pre-line' }}>{location?.state?.message}</p>
                </div>
        </div>
        )
      }
      </div>
    </div>
  )
}

export default GospelNotes
