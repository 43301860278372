import React, {memo, useState, useMemo, useRef, useEffect} from 'react'
import data from '../utils/data'
import navigationDown from '../assets/arrow_circle_down.svg'
import navigationUp from '../assets/arrow_circle_up.svg'
import documentIcon from '../assets/desc_fill.svg'
import favFilled from '../assets/heart_fill.svg'
import favNoFill from '../assets/heart_nofill.svg'
import shareIcon from '../assets/share_fill.svg'
import playIcon from '../assets/play_circle.svg'
import pauseIcon from '../assets/pause_circle.svg'
import switchIcon from '../assets/switch_fill.svg'
import homeIcon from '../assets/dashboard_fill.svg'
import searchIcon from '../assets/pageview_fill.svg'
import mp3Seven from '../storage/songs/007.mp3'
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import { Document, Page, pdfjs } from "react-pdf";
import {useLocation, useNavigate} from 'react-router-dom';
import { useDb } from './DbContext';
import HymnPicker from './HymnPicker'
import SearchHymn from './SearchHymn'
import { Route, Routes } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

ReactModal.setAppElement('*'); 
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const ViewHymn = () => {

    const localStorageTag = "hymn.adediranife.favorites"
    const location = useLocation();
    const navigate = useNavigate();
    const dbContext = useDb();
    if (!dbContext) {
        // fetching records...
    }
    const { loadHymn, loadYorubaHymn, loadFrenchHymn, loadSpanishHymn} = dbContext;
    const englishHeader = data().englishHeader;
    const spanishHeader = data().spanishHeader;
    const yorubaHeader = data().yorubaHeader;
    const frenchHeader = data().frenchHeader;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [backgroundHeader, setBackgroundHeader] = useState('');
    const [saved, setSaved] = useState([]);

    //{ id: "001", isFavorite: false }]);
    const [items, setItems] = useState([]);

    const [likeHymn, setLikeHymn] = useState(favFilled)
    const [playButton, setPlayButton] = useState(playIcon)

    const [audio, setAudio] = useState(null);
    const [prevLocation, setPrevLocation] = useState(location);

    useEffect(() => {
        // Check if the state data in the location has changed
        if (prevLocation.state !== location.state) {
          // Execute your logic here when location state changes
          import(`../storage/songs/${location.state.key}.mp3`)
            .then(module => {
                setAudio(new Audio(module.default));
            })
            .catch(error => {
                try {
                    audio.pause();
                } catch (error) {
                    //do nothing
                }
                setAudio(null);
                // console.log('Error loading file..location', );
            });
        }
    
        // Update the previous location state to the current location state
        setPrevLocation(location);
      }, [location, prevLocation]);

    useEffect(() => {
        // Dynamically import the module based on the provided path
        // console.log(`../storage/songs/${location?.state?.key}.mp3`)
        // import(`../storage/songs/${location.state.key}.mp3`)
        if(location?.state?.flavor == "english")
            setBackgroundHeader(englishHeader)
        else if(location?.state?.flavor == "yoruba")
            setBackgroundHeader(yorubaHeader)
        else if(location?.state?.flavor == "spanish")
            setBackgroundHeader(spanishHeader)
        else if(location?.state?.flavor == "french")
            setBackgroundHeader(frenchHeader)

       import(`../storage/songs/${location.state.key}.mp3`)
        .then(module => {
            setAudio(new Audio(module.default));
        })
        .catch(error => {
            // console.log('Error loading file:', );
            setAudio(null);
        });

        return () => {
            try {
                audio.pause()
            } catch (error) {
                //do nothing
            }
           
        };

    }, []);

    const [isValid, setIsValid] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    // const [url, setUrl] = useState(`http://localhost:8080/external?url=https://hymn.insertdistinct.com/pdf/${location?.state?.key}.pdf`);
    const [url, setUrl] = useState(`https://gospel-hymn-project-9896719c7b0b.herokuapp.com/external?url=https://hymn.insertdistinct.com/pdf/${location?.state?.key}.pdf`);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [currentCategory, setCurrentCategory] = useState('');
    const [options, setOptions] = useState([]);

    const recipient = 'support@gospelhymns.cartglu.com';
    const subject = 'Contact Us | Gospel Hymn Book';
    const body = 'Kindly share your request/questions.';
    const [favorites, setFavorites] = useState([]);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
      };
    
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleOptionSelect = (option) => {
        // Perform action based on the selected option

        // console.log(`Selected option: ${option["value"]}`);
        let val = option["value"];
        let filter = null;
        let dt = null;

        // console.log(val, currentCategory)
        if(currentCategory !== val){

            if(val == "english"){
                filter = loadHymn.filter(obj => obj.tno === location.state.key);
                setCurrentCategory(val)
            } else if(val == "french"){
                filter = loadFrenchHymn.filter(obj => obj.tno === location.state.key);
                setCurrentCategory(val)
            } else if(val == "spanish"){
                filter = loadSpanishHymn.filter(obj => obj.tno === location.state.key);
                setCurrentCategory(val)
            } else if(val == "yoruba"){
                filter = loadYorubaHymn.filter(obj => obj.tno === location.state.key);
                setCurrentCategory(val)
            }

            dt = { 
                key: filter[0].tno,
                tname: filter[0].tname,
                verse: filter[0].verse,
                category: filter[0]?.category,
                navpage: location.state.navpage + 1,
                flavor: val
            };
           
            navigate('/view-hymn', { 
                state: dt
            })

            handleCloseDialog()
        }
    };

    const openGmailCompose = () => {
      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    };

    const shareHymn = () => {
        let subject = "Check out Gospel Hymn Book"
        let body = "Gospel Hymn Book \n\n" + location?.state.verse
        const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
      };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    useEffect(() => { //load storage items
        const storedFavorites = JSON.parse(localStorage.getItem(localStorageTag)) || [];
        setItems(storedFavorites);
        // console.log(storedFavorites, 11, location.state.key) 
        const itemIndex = storedFavorites.find(item => item.id === location.state.key);
        if (itemIndex) {
            setLikeHymn(favNoFill)
        } else {
            setLikeHymn(favFilled)
        }

        if(location.state?.category === "fr"){
            setCurrentCategory('french')
        }else if(location.state?.category === "es"){
            setCurrentCategory('spanish')
        } else if(location.state?.category?.trim() === ""){
            setCurrentCategory('yoruba')
        } else {
            setCurrentCategory('english')
        }

        // return () => {
        //    console.log("unmount page")
        //   };
        
      }, []);

    useEffect(() => { //save
            let favs = items.filter(item => item.isFavorite).map(item => ({id: item.id, isFavorite: true}));

            // const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
            // if(favs.length == 0){
            //     favs = storedFavorites
            // }

            setFavorites(favs)
            const result = favs.find(item => item.id === location.state.key);
            if(result){
                setLikeHymn(favNoFill)
            } else {
                setLikeHymn(favFilled)
            }
            // console.log(favorites, 22)
            localStorage.setItem(localStorageTag, JSON.stringify(favs));
      }, [items]);

    const musicTones = () => { //
        const result = checkFileExists()
        if (audio) {
            if (isPlaying) {
              audio.pause();
              setPlayButton(playIcon)
            } else {
              audio.play();
              setPlayButton(pauseIcon)
            }
            setIsPlaying(!isPlaying);
        }
    }

    const favorite = () => { 

        setItems(prevItems => {
            const itemIndex = prevItems.findIndex(item => item.id === location.state.key);
            if (itemIndex === -1) {
                setLikeHymn(favNoFill)
              // Add the new item to favorites
              const newItem = { id: location.state.key, isFavorite: true };
              return [...prevItems, newItem];
            } else {
                if(likeHymn == favFilled){
                    setLikeHymn(favNoFill)
                } else {
                    setLikeHymn(favFilled)
                }
              return prevItems.map(item =>
                item.id === location.state.key ? { ...item, isFavorite: !item.isFavorite } : item
              );
            }
          });
       
    }

    const checkPageValidity = async () => {
        try {
          const response = await fetch(url,  {
            method: 'GET',
            referrerPolicy: 'origin',
            mode:'cors',
            headers: {
              'X-Requested-With': 'XMLHttpRequest', // Set the header here
                },
            })
    
          if (response.ok) {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        } catch (error) {
          setIsValid(false);
        }
      };

    const musicNotes = () => { 
        //http://docs.google.com/gview?embedded=true&url=" + customURL
        //extension: Allow CORS: Access-Control-Allow-Origin
        const ext = ["pdf", "jpg", "png"]
        checkPageValidity()
        if(isValid){
            openModal()
        }
        
    }

    // useEffect(() => {
    //     makeAPICall();
    //   }, [])

    const onShare = () => {
        
        const title ="Gospel Hymn Book"
        const text ="Check out this amazing hymn app"
        const shareurl = "cartglu.com"
        const toBeShared = {
          title,
          text,
          shareurl
        };
        if (navigator.share) {
          navigator
            .share({
              ...toBeShared
            })
            .then(() => console.log("Share was successful."))
            .catch(error => console.log("Sharing failed", error));
        } else {
          alert("Your browser doesn't support the Share Intent");
        }

      };

      const hymnOptions = () => {

      }

      const nextHymn = () => {

        let dt = null;
        let filter = null;
        let num = null;
        let various = false;
        let newValue = "";
        if(location.state.key.includes("V")){
            num = Number(location.state.key.substr(1,))
            various = true;
            
        } else {
            num = Number(location.state.key)
        }

        if(num == 1000){
            newValue = "V001"
        } else if(num == 50 && various){
            newValue = "001"
        } else {
            let temp = String(num+1)
            let len = temp.length
            if(len == 3 | len == 4){
                newValue = temp
            }
            if(len == 2){
                newValue = "0" + temp
            }
            if(len == 1){
                newValue = "00" + temp
            }
            if(various){
                newValue = "V" + newValue
            }
        }

        if(location.state?.category === "fr"){
            filter = loadFrenchHymn.filter(obj => obj.tno === newValue);
        }else if(location.state?.category === "es"){
            filter = loadSpanishHymn.filter(obj => obj.tno === newValue);
        } else if(location.state?.category.trim() === ""){
            filter = loadYorubaHymn.filter(obj => obj.tno === newValue);
        } else {
            filter = loadHymn.filter(obj => obj.tno === newValue);
        }

        dt = { 
            key: filter[0].tno,
            tname: filter[0].tname,
            verse: filter[0].verse,
            category: filter[0]?.category,
            navpage: location.state.navpage + 1,
            flavor: location?.state?.flavor,
        };

        const searchItem = filter[0].tno;
        const result = favorites.find(item => item.id === searchItem);
        if(result){
            setLikeHymn(favNoFill)
        } else {
            setLikeHymn(favFilled)
        }
       
        navigate('/view-hymn', { 
            state: dt
        })

      }

      const prevHymn = () => {

        let dt = null;
        let filter = null;
        let num = null;
        let various = false;
        let newValue = "";
        if(location.state.key.includes("V")){
            num = Number(location.state.key.substr(1,))
            various = true;
            
        } else {
            num = Number(location.state.key)
        }

        if(num == 1 && !various){
            newValue = "V050"
        } else if(num == 1 && various){
            newValue = "1000"
        } else {
            let temp = String(num-1)
            let len = temp.length
            if(len == 3){
                newValue = temp
            }
            if(len == 2){
                newValue = "0" + temp
            }
            if(len == 1){
                newValue = "00" + temp
            }
            if(various){
                newValue = "V" + newValue
            }
        }

        
        if(location.state?.category === "fr"){
            filter = loadFrenchHymn.filter(obj => obj.tno === newValue);
        }else if(location.state?.category === "es"){
            filter = loadSpanishHymn.filter(obj => obj.tno === newValue);
        } else if(location.state?.category.trim() === ""){
            filter = loadYorubaHymn.filter(obj => obj.tno === newValue);
        } else {
            filter = loadHymn.filter(obj => obj.tno === newValue);
        }

        dt = { 
            key: filter[0].tno,
            tname: filter[0].tname,
            verse: filter[0].verse,
            category: filter[0]?.category,
            navpage: location.state.navpage + 1,
            flavor: location?.state?.flavor,
        };

        const searchItem = filter[0].tno;
        const result = favorites.find(item => item.id === searchItem);
        if(result){
            setLikeHymn(favNoFill)
        } else {
            setLikeHymn(favFilled)
        }
       
        navigate('/view-hymn', { 
            state: dt
        })
          
      }

      const goBack = () => {
        
        // const data = { 
        //     category: location?.state.flavor
        // };
        // navigate(-1, { 
        //     state: data
        // })

        navigate(location.state.navpage * -1)

        // navigate(-1)
        // window.history.back();
      }
    
    // const openDialog = (msg, opts) => {
    //     setMessage(msg);
    //     setOptions(opts);
    //     setIsOpen(true);
    // };

    // const handleOptionClick = (option) => {
    //     setIsOpen(false);
    //     // Handle the chosen option here
    //     console.log('Chosen option:', option);
    // };

    const homeScreen = () => {
        navigate('/dashboard')
    }

    const handleSearchHymn = () => {
        // console.log(location.state.category)
        navigate('/search-hymn', {
            state: {
                flavor: location?.state?.flavor,
                navpage: location.state.navpage + 1
            }
        })
    }

    const checkFileExists = () => {
        const fileUrl = `../storage/songs/${location.state.key}.mp3`;
        // Attempt to fetch the file
        import(`../storage/songs/${location.state.key}.mp3`)
        .then(module => {
            // setAudio(new Audio(module.default));
            // setAudio(null)
        })
        .catch(error => {
            alert('File not available');
            try {
                setPlayButton(playIcon)
                setIsPlaying(!isPlaying);
                audio.pause();
            } catch (error) {
                //do nothing
                // console.log(error)
            }
        });
    }


  return (
    <div className="relative h-screen">
        <>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                appElement={document.getElementById('app_loader')}
                contentLabel="Gospel Hymn - Music Notes"
            >
                <div>
                    <Document
                        file={url}
                        onContextMenu={(e) => e.preventDefault()}
                        className="pdf-container">
                        <Page pageNumber={1} />
                    </Document>
                </div>
                <button className="h-10 font-bold text-white bg-red-400 w-full focus:outline-none" onClick={closeModal}>Close</button>
            </Modal>
       </>
       <div>
            <HymnPicker
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                onOptionSelect={handleOptionSelect}
                category={currentCategory}
            />
       </div>
        <div className="grid justify-items-center h-1/5">
            <div className="relative overflow-hidden">
                    {/* brightness-50 */}
                <img src={backgroundHeader} onClick={goBack}
                    className="flex w-screen bg-cover"/>
                <h3 className="absolute w-screen text-3xl text-white top-1/2 left-1/2 -translate-x-1/2 text-center">Gospel Hymn: {location.state.key}</h3>
            </div>
        </div>
        <div id="app_loader" style={{height: `55vh`}} className="overflow-y-scroll p-7 h-3/5 pb-10 no-scrollbar">
            <div className="">
                <div className="flex">
                    <div className="text-black w-10/12">
                        {location.state?.tname?.length < 70 ? (
                            <h3 className="text-2xl">{location.state?.tname}</h3>
                        ) : null}
                        {location.state?.tname?.length >= 70 ? (
                            <h3 className="text-lg">{location.state?.tname}</h3>
                        ) : null}
                    </div>
                    <div className="w-1/12"></div>
                    <div className="top-0 item-right flex flex-col justify-center">
                        <img src={navigationUp} onClick={nextHymn}/>
                        <img className="mt-5" src={navigationDown} onClick={()=>prevHymn()} />
                    </div>
                </div>
                <p className="text-gray-400">
                {location.state?.category?.trim() == "" ? ("Yoruba Version") : null}
                {location.state?.category == "fr" ? ("French Version") : null}
                {location.state?.category == "es" ? ("Spanish Version") : null}
                {location.state?.category?.trim() !== "" &&  location.state?.category !== "es" &&  location.state?.category !== "fr" ? ("English Version") : null}
                </p>
            </div>
            <div className="h-0.5 divide-y bg-gray-200 mt-8"></div>
            <LazyLoad offset={50}>
                <div className="flex flex-row my-5 justify-around h-10">
                    <div className="flex">
                        <img src={documentIcon} onClick={()=>musicNotes()}/>
                    </div>
                    <div className="w-0.5 h-full divide-x bg-gray-200"></div>
                    <div className="flex">
                        <img src={likeHymn} onClick={()=>favorite()}/>
                    </div>
                    <div className="w-0.5 h-full divide-x bg-gray-200"></div>
                    <div className="flex">
                        <img src={switchIcon} onClick={()=>handleOpenDialog()}/>
                    </div>
                    <div className="w-0.5 h-full divide-x bg-gray-200"></div>
                    <div className="flex">
                        <img src={searchIcon} onClick={()=>handleSearchHymn()}/>
                    </div>
                    <div className="w-0.5 h-full divide-x bg-gray-200"></div>
                    <div className="flex">
                        <img src={homeIcon} onClick={()=>homeScreen()}/>
                    </div>
                </div>
            </LazyLoad>
            
            <div className="h-0.5 divide-y bg-gray-200 my-4"></div>
            <p className="text-md pt-4 w-12/12" style={{ whiteSpace: 'pre-line' }}>
                {location.state?.verse}
            </p>
            <div className="flex flex-row my-5 h-14">
                <div className="flex items-center">
                    <p className="">Listen to hymn: </p>
                    <div></div>
                    <img src={playButton} onClick={()=>musicTones()}/>
                </div>
            </div>
            {/* <p className="text-center text-gray-500 my-5">Have a question?</p>
            <div className="w-full flex justify-center">
                <button onClick={openGmailCompose} className="h-10 font-bold text-white bg-red-400 w-full focus:outline-none" >SUBMIT A REQUEST</button>
            </div> */}
        </div>
        <div>
            <p className="text-center text-gray-500 mt-5">Have a question?</p>
            <div className="w-full flex justify-center items-center mt-4">
                <button onClick={openGmailCompose} className="h-10 font-bold text-white bg-red-400 w-1/2 focus:outline-none" >SUBMIT A REQUEST</button>
            </div>
        </div>
        
    </div>
  )
}

export default memo(ViewHymn)
