import React, { useState, useEffect } from 'react';
import './styles/SearchHymn.css'; // Import your CSS file for styling
import {useLocation, useNavigate} from 'react-router-dom';
import {MdArrowBack} from 'react-icons/md';

import bonarho from '../assets/authors/bonarho.jpg'
import crosby from '../assets/authors/crosby.jpg'
import blissbwr from '../assets/authors/blissbwr.jpg'
import wesleyr3 from '../assets/authors/wesleyr3.jpg'
import lowryr1 from '../assets/authors/lowryr1.jpg'
import cowper from '../assets/authors/cowper.jpg'
import cushr from '../assets/authors/cushr.jpg'
import doane3 from '../assets/authors/doane3.jpg'
import kirk from '../assets/authors/kirk.jpg'
import mcgran1r from '../assets/authors/mcgran1r.jpg'
import newtonsr from '../assets/authors/newtonsr.jpg'
import sankey6r from '../assets/authors/sankey6r.jpg'
import stebbins from '../assets/authors/stebbins.jpg'
import whittsr3 from '../assets/authors/whittsr3.jpg'
import hoffm1r from '../assets/authors/hoffm1r.jpg'
import haver2r from '../assets/authors/haver2r.jpg'
import excell3 from '../assets/authors/excell3.jpg'
import bradr from '../assets/authors/bradr.jpg'
import muller from '../assets/authors/muller.jpg'
import towner from '../assets/authors/towner.jpg'

const Articles = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [authorImage, setAuthorImage] = useState('');
    const flavor = location?.state?.flavor;
    const author = location?.state?.author;

    useEffect(() => {
        if (author == "Horatius Bonar") {
            setAuthorImage(bonarho);
        } else if (author === "Fanny Crosby") {
            setAuthorImage(crosby);
        } else if (author === "Philip P. Bliss") {
            setAuthorImage(blissbwr);
        } else if (author === "Charles Wesley") {
            setAuthorImage(wesleyr3);
        } else if (author === "Robert Lowry") {
            setAuthorImage(lowryr1);
        } else if (author === "William Cowper") {
            setAuthorImage(cowper);
        } else if (author === "William O. Cushing ") {
            setAuthorImage(cushr);
        } else if (author === "William H. Doane") {
            setAuthorImage(doane3);
        } else if (author === "Wm. J. Kirkpatrick") {
            setAuthorImage(kirk);
        } else if (author === "James McGranahan") {
            setAuthorImage(mcgran1r);
        } else if (author === "John Newton") {
            setAuthorImage(newtonsr);
        } else if (author === "Ira David Sankey") {
            setAuthorImage(sankey6r);
        } else if (author === "George Coles Stebbins") {
            setAuthorImage(stebbins);
        } else if (author === "Daniel B. Towner") {
            setAuthorImage(towner);
        } else if (author === "Daniel Webster Whittle") {
            setAuthorImage(whittsr3);
        } else if (author === "Elisha Albright Hoffman") {
            setAuthorImage(hoffm1r);
        } else if (author === "Frances Ridley Havergal") {
            setAuthorImage(haver2r);
        } else if (author === "E. O. Excell") {
            setAuthorImage(excell3);
        } else if (author === "William Batchelder Bradbury") {
            setAuthorImage(bradr);
        } else if (author === "George Muller") {
            setAuthorImage(muller);
        }

    }, []);

    const goBack = () => {
        navigate(-1)
    }

    const recipient = 'support@gospelhymns.cartglu.com';
    const subject = 'Contact Us | Gospel Hymn Book';
    const body = 'Kindly share your request/questions.';

    const openGmailCompose = () => {
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
      };

  return (
    <div className="absolute z-10 bg-white overflow-hidden">
    <div className="search-page" style={{height: '100%'}}>
      <div className="toolbar flex items-center">
        <div className="go-back">
            <MdArrowBack size="26" className="text-white" onClick={goBack}/>
        </div>
        <h1 className="toolbar-title pl-1 text-md"><span className="uppercase">{location?.state?.author}</span></h1>
      </div>

      {
        !location?.state?.mode && (
            <>
            <div className="flex flex-col items-center mt-5">
            <img src={authorImage} className="h-50 "/>
            <div style={{ height: '40vh' }} className="pl-7 pr-7 pt-3 mt-7 overflow-y-scroll no-scrollbar">
            <p style={{ whiteSpace: 'pre-line' }}>{location?.state?.description}</p>

            </div>
            </div>

            <div>
                <p className="text-center text-gray-500 mt-5">Have a question?</p>
                <div className="w-full flex justify-center items-center mt-4">
                    <button onClick={openGmailCompose} className="h-13 p-3 font-bold text-white bg-red-400 focus:outline-none" >SUBMIT A REQUEST</button>
                </div>
            </div>
            </>
        )
      }

    {
        location?.state?.mode === "desktop" && (
            <div className="flex flex-col mt-5 overflow-hidden">
                <div className="mx-7 flex items-center justify-center w-10/12">
                    <img className="rounded" src={authorImage} style={{
                        width: '100px'
                    }} />
                </div>
                <div style={{ height: '48vh', paddingRight: '10px' }} className="pl-7 mt-5 overflow-y-scroll no-scrollbar">
                    <p className="w-full" style={{ whiteSpace: 'pre-line' }}>{location?.state?.description}</p>
                </div>
        </div>
        )
      }

        
      </div>
    </div>
  )
}

export default Articles
