import React, { useState, useRef, useEffect } from 'react';
import { Link, Route, Routes, Switch } from 'react-router-dom';
import Pages  from './Pages';
import Desktop from './Desktop'

const Home = () => {

    const scrollRef = useRef(null);

    // useEffect(() => {
    //   if (scrollRef.current) {
    //     scrollRef.current.scrollTo(0, 0);
    //   }
    // }, []);

  const [isMobileOrTablet, setIsMobileOrTablet] = useState(checkIfMobileOrTablet());

  useEffect(() => {
    function handleResize() {
      setIsMobileOrTablet(checkIfMobileOrTablet());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function checkIfMobileOrTablet() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    // Define the maximum dimensions for a mobile or tablet device
    const maxMobileWidth = 768; // You can adjust this value as needed
    const maxTabletWidth = 1024; // You can adjust this value as needed

    return width <= maxMobileWidth || (width <= maxTabletWidth && height <= maxMobileWidth);
  }
    
  return (
    <>
        <div className="min-h-full flex" ref={scrollRef}>
        {/* <Routes>
        {isMobileOrTablet ? (
              <Route path="/*" element={<Pages />} />
           ) : (
              <Route path="/*" element={<Desktop />} />
           ) }
        </Routes> */}
        <Routes>
          <Route path="/*" element={<Desktop />} />
        </Routes>
      </div>
    </>
  )
}

export default Home